@use 'global' as *;

.p-case__title {
  margin: 3em 0 2.5em;
  text-align: center;

  img {
    width: 90%;
    max-width: 830px;
    display: inline-block;

    @include min-screen("md") {
      width: auto;
    }
  }
}