@use 'global' as *;

.p-seminar__title {
  text-align: center;
}

.seminar-title__copy {
  margin: 3em 0 2.5em;
  text-align: center;

  img {
    display: inline-block;
  }
}

.p-seminar__title-block {
  display: block;

  @include min-screen("md") {
    display: flex;
    justify-content: space-between;
  }
}

.p-seminar__title-block-ph {
  width: 100%;
  margin-right: 0;
  padding-top: 40%;
  position: relative;
  overflow: hidden;

  @include min-screen("md") {
    width: 32%;
    margin-right: 5%;
    padding-top: 28%;
  }

  img {
    width: 115%;
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%, -10%);

    @include min-screen("md") {
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.p-seminar__title-block-list {
  flex: 1;
  display: grid;

  dt {
    @include font-size(17);
    font-weight: 600;
    line-height: 1.3;
    padding: 1em 0;

    @include min-screen("md") {
      font-size: min(2vw, 23px);
      padding: 0.3em 0;
      margin-bottom: 0.5em;
    }

    @include min-screen(1050px) {
      @include font-size(23);
    }
  }

  dd {
    margin-top: auto;

    ul {
      li {
        @include font-size(16);
        line-height: 1.3;
        padding: 0.8em 1em 0.8em 3em;
        text-align: left;
        position: relative;
        box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.4);

        @include min-screen("md") {
          font-size: min(1.9vw, 20px);
        }

        @include min-screen(1050px) {
          @include font-size(20);
        }

        &:before {
          content: "";
          width: 1.2em;
          height: 0.8em;
          display: block;
          background: url(/assets/img/seminar/seminar__check.svg) no-repeat center center/contain;
          position: absolute;
          top: 50%;
          left: 3%;
          transform: translateY(-50%);
        }

        &+li {
          margin-top: 0.7em;
        }
      }
    }
  }
}