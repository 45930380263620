@use 'global' as *;

.p-detail {

  .tag__column,
  .tag__case,
  .tag__seminar {
    margin: 0 0 0.5em;

    span,
    a {
      color: #FFF;
      font-weight: 700;
      padding: 0.2em 0.4em 0.3em;
      background: $color-main;
      display: inline-block;
    }
  }

  .tag__case span,
  .tag__case a {
    background: #000;
  }

  .tag__seminar span {
    &.receipt {
      background: $color-main-accent;
    }

    &.end {
      background: $color-gray-300;
    }
  }

  &__date {
    color: #747474;
    text-align: right;
  }

  h1 {
    @include font-size(20);
    text-align: left;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 1em;

    @include min-screen("md") {
      @include font-size(45);
    }
  }

  // p+h1 {
  //   margin-top: 1em;
  // }

  h2 {
    width: 100%;
    max-width: 880px;
    padding: 0.5rem 0.5rem;
    text-align: center;
    @include font-size(17);
    color: #fff;
    font-weight: 700;
    line-height: 1.4;
    background: #323c69;
    margin-top: 1.5em;

    @include min-screen("md") {
      @include font-size(33);
    }
  }

  h3 {
    @include font-size(15);
    color: #323232;
    font-weight: 700;
    line-height: 1.5;
    border-bottom: 1px solid #b6b6b6;
    margin-top: 1.5em;
    padding: 0.2em 0;

    @include min-screen("md") {
      @include font-size(24);
    }
  }

  h4 {
    @include font-size(14);
    color: #323232;
    font-weight: 700;
    line-height: 1.5;
    margin-top: 1.5em;

    @include min-screen("md") {
      @include font-size(20);
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #d7d7d7;
    margin-top: 1.5em;

    @include min-screen("md") {
      max-width: 696px;
      margin: 1em auto 0;
    }

    & th,
    td {
      padding: 0.25rem;
      text-align: center;
      @include font-size(12);
      color: #323232;
      font-weight: 500;
      line-height: 1.4;
      border-collapse: collapse;
      border: 1px solid #d7d7d7;

      @include min-screen("md") {
        @include font-size(14);
      }

      p {
        line-height: 1.4;
        margin: 0;
      }
      span {
        font-size: 12px !important;
        @include min-screen("md") {
          font-size: 14px !important;
        }
      }
      
    }

    & th {
      background: #E8E8E8;
    }

    & td {
      padding: 0.5rem;
    }
  }

  p {
    line-height: 1.7;
    margin-top: 2em;

    img {
      display: block;
      margin: 0 auto;
    }

    &+ul {
      margin-top: 1.5em;
    }

    u,
    span {
      text-decoration: none !important;
      background: linear-gradient(transparent 75%, #fedc0b 75%);
    }

    span[style|="font"] {
      background: none !important;
    }

  }

  a:not(.c-btn__more a, .tag__column a) {
    color: $color-active;
  }

  ul {
    margin-top: 1em;

    li {
      line-height: 1.2;
      margin-left: 1.3em;
      list-style-type: disc;

      &+li {
        margin-top: 0.5em;
      }
    }
  }

  .table-contents {
    margin-top: 1.5em;
    padding: 1rem;
    background: #f7f7f7;

    @include min-screen("md") {
      padding: 2rem;
    }

    h2 {
      @include font-size(20);
      color: #323232;
      font-weight: 700;
      line-height: 1.4;
      background: none;
      text-align: left;
      margin-top: 0;

      @include min-screen("md") {
        @include font-size(36);
      }
    }
  }

  .mokuji-list {
    li {
      list-style-type: none;
    }

    ul {
      margin-top: 0.5em;
    }

    >ul {
      >li {
        >a {
          @include font-size(16);
        }

        >ul {
          >li {
            >a {
              @include font-size(15);
            }

            >ul {
              >li {
                >a {
                  @include font-size(14);
                }
              }
            }
          }
        }
      }
    }
  }

  .mokuji-list {
    li {
      list-style-type: none;

      &.indent-1 {
        margin-left: 1em;

        >a {
          @include font-size(16);
        }
      }

      &.indent-2 {
        margin-left: 2em;

        >a {
          @include font-size(15);
        }
      }

      &.indent-3 {
        margin-left: 3em;

        >a {
          @include font-size(14);
        }
      }
    }

    ul {
      margin-top: 0.5em;
    }

    >ul {
      >li {
        >a {
          @include font-size(16);
        }

        >ul {
          >li {
            >a {
              @include font-size(15);
            }

            >ul {
              >li {
                >a {
                  @include font-size(14);
                }
              }
            }
          }
        }
      }
    }
  }

  .column__recommend {
    border: 2px solid $color-red;
    margin-top: 1em;
    padding: 1.5em;

    h3 {
      margin: 0;
    }
  }

  .supervision {
    margin-top: 2em;
    padding: 2em;
    border: 1px solid $color-gray-100;
    text-align: center;
  }

  .supervision .supervision__title {
    padding: 0 1em;
    margin: 0 0 1.3em;
    text-align: center;
    display: inline-block;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      display: inline-block;
      width: 0.5em;
      height: 1px;
      background: #000;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }

  .supervision__info {
    display: block;

    @include min-screen("md") {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .supervision__info-ph {
    width: 170px;
    height: 130px;
    margin: 0 auto 1em;
    // padding-top: 50%;
    background: $color-gray-100;
    position: relative;
    overflow: hidden;

    @include min-screen("md") {
      width: 20%;
      margin-right: 4%;
      margin-bottom: 0;
      padding-top: 20%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .supervision__info-text {
    flex: 1;
    text-align: left;

    dt {
      @include font-size(14);
      font-weight: 700;
      margin-bottom: 0.7em;
      padding: 0.3em 0 0.7em;
      border-bottom: 1px solid $color-gray-100;

      @include min-screen("md") {
        @include font-size(20);
      }

      span {
        @include font-size(13);
        font-weight: 500;
        margin-bottom: 0.8em;
        display: block;

        @include min-screen("md") {
          @include font-size(16);
        }
      }
    }

    dd {
      @include font-size(13);
      font-weight: 400;
      line-height: 1.6;

      @include min-screen("md") {
        @include font-size(16);
      }
    }
  }
}

// .c-btn__more {
//   margin-top: 2em;
// }

.contact-main__btnline {
  margin: 1em 0 2em;
  display: block;
  text-align: center;

  @include min-screen("md") {
    margin: 3em 0 2em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.contact-main__btnline .c-btn__more {
  margin: 0;
  order: 2;
}

.contact-main__btnline .c-btn__back {
  margin: 1em 0 0;
  order: 1;

  @include min-screen("md") {
    margin: 0 2em 0 0;
  }
}

// .column__dialog {
//   margin: 2em 0;
// }

.column__dialog-comment {
  margin: 1.5em 0;
  display: flex;
  justify-content: space-between;

  .column__dialog-comment-text {
    margin: 0 0 0 2em;
    margin-left: 2em;
    padding: 1em;
    background: #E8E8E8;
    order: 1;
    flex: 1;
    position: relative;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.7em 1.5em 0.7em 0;
      border-color: transparent #E8E8E8 transparent transparent;
      position: absolute;
      top: 0.5em;
      left: -1em;
    }
  }

  .column__dialog-comment-ph {
    width: 30%;
    margin: 0;
    @include min-screen("md") {
      width: 15%;
    }
    img {
      width: 100%;
    }
  }

  &.left {

    .column__dialog-comment-ph {
      order: 2;
    }

    .column__dialog-comment-text {
      margin: 0 2em 0 0;
      order: 1;
      background: $color-lblue;

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.7em 0 0.7em 1.5em;
        border-color: transparent transparent transparent $color-lblue;
        position: absolute;
        top: 0.3em;
        right: -1em;
        left: auto;
      }
    }
  }
}